<template>
  <div class="Sales">
    <div style="padding-bottom:100px;height: 100%;padding-top: 10px;padding-left: 10px;padding-right: 10px;">
      <div class="top" style="">
        <div class="flex">
          <el-input :placeholder="placeholder" v-model="queryParam.Keywords" @input="inputChange"
            @keydown.enter.native="search"></el-input>
        </div>
        <!-- <div class="flex" style="margin-top: 10px">
          <el-date-picker v-model="StartTime" type="date" placeholder="开始日期" @change="startTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-date-picker v-model="EndTime" type="date" placeholder="结束日期" @change="endTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div> -->
      </div>
      <div style="margin-top:10px">
        <el-table ref="queryForm" :height="tableHeight" element-loading-text="拼命加载中" :data="tableData"
          v-loading="loading" v-el-table-infinite-scroll="scrollLoad" :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="scrollDisaled">
          <el-table-column prop="cCusName" label="客户" align="left" width="100" fixed> </el-table-column>
          <el-table-column prop="cCusName" label="金额" align="left">
            <el-table-column prop="SevenDays" label="0~7天" align="left" width="100">
            </el-table-column>
            <el-table-column prop="ThirtyDays" label="8~30天" align="left" width="100">
            </el-table-column>
            <el-table-column prop="SixtyDays" label="31~60天" align="left" width="100">
            </el-table-column>
            <el-table-column prop="SixtyUpperDays" label="60天以上" align="left" width="100">
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div style=" padding: 20px 10px;
      position: fixed;
      bottom: 0;
      background-color: #fff;
      width: 100%;">
        <div style="display:flex;">
          <!--  -->
          <div style="flex:1;dispaly:flex;align-items:center;padding-top:10px">
            合计
          </div>
          <!-- justify-content: center; -->
          <div style="display:flex; flex:9;justify-content: space-around;width:100%;">
            <div style="display:flex;flex-direction: column;align-items:center" >
              <span>0-7天</span>
              <div style="font-size:13px">{{ toThousands(overdueDayObject.SevenDays) }}</div>
            </div>
            <div style="display:flex;flex-direction: column;align-items:center">
              <span>8-30天</span>
              <div style="font-size:13px">{{toThousands(overdueDayObject.ThirtyDays) }}</div>
            </div>
            <div style="display:flex;flex-direction: column;align-items:center">
              <span>31-60天</span>
              <div style="font-size:13px">{{toThousands(overdueDayObject.SixtyDays) }}</div>
            </div>
            <div style="display:flex;flex-direction: column;align-items:center">
              <span>60天以上</span>
              <div style="font-size:13px">{{toThousands(overdueDayObject.SixtyUpperDays) }}</div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import { toThousands } from "../../../utils/Tools";
export default {
  data() {
    return {
      scrollDisaled: false,
      tableHeight: 200,
      // 千分位格式化
      toThousands,
      // 下拉选择数据
      options: [{
        value: '0',
        label: '客户'
      }, {
        value: '1',
        label: '业务员'
      }],
      // 表格属性数据
      tablePropListData: [
        // {
        //   label:"日期",
        //   prop:'CreateTime'
        // },
        {
          label: "客户",
          prop: 'Name'
        },
        {
          label: "金额",
          prop: "totalSum"
        }
      ],
      loading: false,
      // 开始日期
      StartTime: "",
      // 结束日期
      EndTime: "",
      // 收款统计列表数据
      tableData: [],
      selectKey: "客户",
      placeholder: "请输入客户名称进行查询",
      searchKey: "",
      date: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: [10, 30, 50],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: { CompanyId: "", Keywords: "" },
      // 逾期天数底部总金额统计数据
      overdueDayObject: {
        // 、0-7天
        SevenDays: 0,
        // 、8-30天
        ThirtyDays: 0,
        // 、31-60天
        SixtyDays: 0,
        // 60天以上
        SixtyUpperDays: 0
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height = document.documentElement.clientHeight - this.$refs.queryForm.$el.offsetHeight;
      this.tableHeight = height+20;
    })
  },
  created() {
    document.title = "逾期天数统计"

    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }

    this.queryParam.CompanyId = this.$route.query.CompanyId
    this.pagination.token = this.$route.query.token

    // console.log('获取路由获取路由获取路由获取路由',this.$route.query,this.pagination)

    this.toGetList();

  },
  methods: {
    toGetList() {
      this.pagination.current = 1;
      this.scrollDisaled = true;
      this.tableData = [];
      // 获取逾期天数统计列表数据
      this.getCollectionStatistics()
      // 获取逾期天数统计底部总金额统计数据
      this.GetOverdueAllStatistics()
    },
    // 表格滚动加载
    scrollLoad() {
      // 监听表格滚动事件
      this.pagination.current++;
      if (this.pagination.current > 1 && this.tableData.length == this.pagination.total) {
        return
      }

      // 获取逾期天数统计列表数据
      this.getCollectionStatistics()
    },
     // 搜索
     search() {
      // 同时获取逾期天数统计列表数据和逾期天数总金额统计总金额数据
      this.toGetList()
    },

    //  获取逾期天数总金额统计数据
    GetOverdueAllStatistics() {

      this.$http.post("/BO/BIlling/GetDaysAllStatistics", {
        PageIndex: this.pagination.current,
        PageRows: this.pagination.pageSize,
        Search: this.queryParam
      }).then((resJson) => {
        if (resJson.Success) {
          this.loading = false
          console.log('获取逾期T天数天数天天天统计', resJson.Data)
          if (resJson.Data == null) {
            this.overdueDayObject = {
                // 、0-7天
                SevenDays: 0,
                // 、8-30天
                ThirtyDays: 0,
                // 、31-60天
                SixtyDays: 0,
                // 60天以上
                SixtyUpperDays: 0
            }
            return
          }
          this.overdueDayObject.SevenDays = resJson.Data.SevenDays
          this.overdueDayObject.ThirtyDays = resJson.Data.ThirtyDays
          this.overdueDayObject.SixtyDays = resJson.Data.SixtyDays
          this.overdueDayObject.SixtyUpperDays = resJson.Data.SixtyUpperDays
        } else {
          this.loading = false
        }
      });


    },


    // 时间选择
    startTimeChange(e) {
      console.log('开始时间', e)
      this.queryParam.StartDate = e
      // 同步获取逾期天数统计列表数据和逾期天数总金额统计列表数据
      this.toGetList()

    },
    endTimeChange(e) {
      console.log('结束时间', e)
      this.queryParam.EndDate = e
      this.toGetList()

    },
    // 搜索输入框输入
    inputChange(e) {
      console.log('搜索框输入', e)
      this.queryParam.Keywords = e
    },
    // 分页事件
    currentChange(page) {
      this.pagination.current = page;
      this.getCollectionStatistics();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getCollectionStatistics();
    },
    // 获取逾期天数统计列表数据
    getCollectionStatistics() {
      this.loading = true;
      this.$http.post("/BO/BIlling/GetDaysStatistics", {
        PageIndex: this.pagination.current,
        PageRows: this.pagination.pageSize,
        Search: this.queryParam
      }).then((resJson) => {
        if (resJson.Success) {
          this.loading = false;

          // 没数据的时候，禁用 infinite-scroll-disabled 滚动事件
          if (resJson.Data.length == 0) {
            this.scrollDisaled = true;
            return
          }

          this.scrollDisaled = false;

          if (resJson.Data) {
            const pagination = { ...this.pagination };
            pagination.total = resJson.Total;
            this.pagination = pagination;
          }

          resJson.Data.forEach(item => {
            item.SevenDays = item.SevenDays == 0 ? '' : toThousands(item.SevenDays)
            item.SixtyDays = item.SixtyDays == 0 ? '' : toThousands(item.SixtyDays)
            item.SixtyUpperDays = item.SixtyUpperDays == 0 ? '' : toThousands(item.SixtyUpperDays)
            item.ThirtyDays = item.ThirtyDays == 0 ? '' : toThousands(item.ThirtyDays)
          })
          this.tableData = this.tableData.concat(resJson.Data);// 分页请求的数据，追加到任务列表中
          if (this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
            return
          }

        } else {
          this.loading = false
        }
      });
    },
    // 下拉选择事件
    selectChange(e) {
      this.queryParam.Keywords = ""
      console.log(e);
      if (e == 0) {
        this.placeholder = "请输入客户名称进行查询"
      } else {
        this.placeholder = "请输入业务员名称进行查询"

      }
      this.queryParam.Type = e
      this.getCollectionStatistics()
      // this.placeholder = "请输入" + e + "名称进行查询";
      // this.searchKey = "";
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}

.Sales {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/App/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;

  .top {
    padding: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
  }
}
</style>